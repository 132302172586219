var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { customElement, property } from 'lit/decorators.js';
import Worker from './sketcher.worker.ts';
import style from './index.css';
const worker = new Worker();
const SUPPORTED_EXTENSIONS = ['.fa', '.fasta', '.fna', '.gz', '.fq', '.fastq'];
let MGnifySourmash = class MGnifySourmash extends LitElement {
    constructor() {
        super();
        this.directory = false;
        this.show_directory_checkbox = false;
        this.show_signatures = false;
        // KmerMinHash parameters
        this.num = 0;
        this.ksize = 31;
        this.is_protein = false;
        this.dayhoff = false;
        this.hp = false;
        this.seed = 42;
        this.scaled = 1000;
        this.track_abundance = false;
        this.selectedFiles = null;
        this.progress = {};
        this.signatures = {};
        this.errors = {};
        worker.addEventListener('message', (event) => {
            var _a;
            switch ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.type) {
                case 'progress:read':
                    this.progress[event.data.filename] = event.data.progress;
                    this.requestUpdate();
                    break;
                case 'signature:error':
                    this.errors[event.data.filename] = event.data.error;
                    this.dispatchEvent(new CustomEvent('sketchedError', {
                        bubbles: true,
                        detail: {
                            filename: event.data.filename,
                            error: event.data.error,
                        },
                    }));
                    this.requestUpdate();
                    break;
                case 'signature:generated':
                    this.signatures[event.data.filename] = event.data.signature;
                    this.progress[event.data.filename] = 100;
                    this.dispatchEvent(new CustomEvent('sketched', {
                        bubbles: true,
                        detail: {
                            filename: event.data.filename,
                            signature: event.data.signature,
                        },
                    }));
                    if (this.haveCompletedAllSignatures()) {
                        this.dispatchEvent(new CustomEvent('sketchedall', {
                            bubbles: true,
                            detail: {
                                signatures: this.signatures,
                                errors: this.errors,
                            },
                        }));
                    }
                    this.requestUpdate();
                    break;
                default:
                    break;
            }
        });
    }
    haveCompletedAllSignatures() {
        return Object.keys(this.progress).every((key) => key in this.signatures || key in this.errors);
    }
    setChecked(event) {
        this.directory = event.target.checked;
    }
    clear() {
        this.selectedFiles = null;
        this.progress = {};
        this.signatures = {};
        this.errors = {};
        this.renderRoot.querySelector('#sourmash-selector').value = null;
        this.requestUpdate();
    }
    renderSelectedFiles() {
        var _a;
        if ((((_a = this.selectedFiles) === null || _a === void 0 ? void 0 : _a.length) || 0) < 1)
            return '';
        return html `
      <div>
        <h2>Selected Files:</h2>
        <ul>
          ${this.selectedFiles.map((file) => {
            var _a;
            const progress = ((_a = this.progress) === null || _a === void 0 ? void 0 : _a[file.name]) || 0;
            const signature = this.signatures[file.name];
            const error = this.errors[file.name];
            let emoji = html ``;
            if (signature)
                emoji = html `✅`;
            if (error)
                emoji = html `<span title=${error}>⚠️<code>${error}</code></span>`;
            return html ` <li>
              ${file.name} ${emoji}
              <progress
                id=${file.name}
                max="100"
                value=${ifDefined(progress > 100 ? undefined : progress)}
              >
                ${progress.toFixed(2)}%
              </progress>
              ${this.show_signatures && (signature === null || signature === void 0 ? void 0 : signature.length)
                ? html `
                    <details>
                      <summary>See signature</summary>
                      <pre>${signature}</pre>
                    </details>
                  `
                : ''}
            </li>`;
        })}
        </ul>
      </div>
    `;
    }
    render() {
        var _a, _b;
        let label = this.directory ? 'Choose a directory...' : 'Choose Files...';
        if ((_a = this.selectedFiles) === null || _a === void 0 ? void 0 : _a.length)
            label = `${(_b = this.selectedFiles) === null || _b === void 0 ? void 0 : _b.length} Files Selected`;
        return html `
      <div class="mgnify-sourmash-component">
        <label
          >Select ${this.is_protein ? 'protein' : 'nucleotides'} FastA
          files:</label
        >
        <label class="file" for="sourmash-selector">
          <input
            type="file"
            id="sourmash-selector"
            name="sourmash-selector"
            accept=${SUPPORTED_EXTENSIONS.join(',')}
            @change=${this.handleFileChanges}
            ?webkitdirectory=${this.directory}
            ?multiple=${!this.directory}
          />
          <span class="file-custom" data-label=${label}></span>
        </label>
        ${this.show_directory_checkbox
            ? html `
              <div class="mode-selector">
                <button
                  class=${this.directory ? '' : 'selected'}
                  @click=${() => (this.directory = false)}
                >
                  Files
                </button>
                <button
                  class=${this.directory ? 'selected' : ''}
                  @click=${() => (this.directory = true)}
                >
                  Directory
                </button>
              </div>
            `
            : ''}
        ${this.renderSelectedFiles()}
      </div>
    `;
    }
    handleFileChanges(event) {
        event.preventDefault();
        this.selectedFiles = Array.from(event.currentTarget.files).filter((file) => {
            for (const ext of SUPPORTED_EXTENSIONS) {
                if (file.name.endsWith(ext)) {
                    return true;
                }
            }
            return false;
        });
        worker.postMessage({
            files: this.selectedFiles,
            options: {
                num: this.num,
                ksize: this.ksize,
                is_protein: this.is_protein,
                dayhoff: this.dayhoff,
                hp: this.hp,
                seed: this.seed,
                scaled: this.scaled,
                track_abundance: this.track_abundance,
            },
        });
        this.dispatchEvent(new CustomEvent('change', {
            bubbles: true,
            detail: {
                selectedFiles: this.selectedFiles,
            },
        }));
        this.requestUpdate();
    }
};
MGnifySourmash.styles = [style];
__decorate([
    property({ type: Boolean, reflect: true })
], MGnifySourmash.prototype, "directory", void 0);
__decorate([
    property({ type: Boolean })
], MGnifySourmash.prototype, "show_directory_checkbox", void 0);
__decorate([
    property({ type: Boolean })
], MGnifySourmash.prototype, "show_signatures", void 0);
__decorate([
    property({ type: Number })
], MGnifySourmash.prototype, "num", void 0);
__decorate([
    property({ type: Number })
], MGnifySourmash.prototype, "ksize", void 0);
__decorate([
    property({ type: Boolean })
], MGnifySourmash.prototype, "is_protein", void 0);
__decorate([
    property({ type: Boolean })
], MGnifySourmash.prototype, "dayhoff", void 0);
__decorate([
    property({ type: Boolean })
], MGnifySourmash.prototype, "hp", void 0);
__decorate([
    property({ type: Number })
], MGnifySourmash.prototype, "seed", void 0);
__decorate([
    property({ type: Number })
], MGnifySourmash.prototype, "scaled", void 0);
__decorate([
    property({ type: Boolean })
], MGnifySourmash.prototype, "track_abundance", void 0);
MGnifySourmash = __decorate([
    customElement('mgnify-sourmash-component')
], MGnifySourmash);
export { MGnifySourmash };
